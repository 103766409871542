import React from 'react';

import withRoot from '../utils/withRoot';
import SEO from '../components/Seo';
import Page from '../components/Page';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    height: '100%',
    padding: 32,
    margin: '80px auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    display: 'block !important',
  },
});

function TosPage(props) {
  const { classes } = props;

  return (
    <Page>
      <SEO title="MotiveBio - Terms of Service" />

      <Grid container spacing={8} className={classes.root}>
        <Grid item xs={12}>
          <h1 className={classNames('typography-title mb-4', classes.title)}>
            Terms of Service
          </h1>
          <p className="typography-p mb-2">
            Welcome to our website. Please read these Terms of Service carefully
            before accessing or using our website. By visiting our site, and
            continuing to browse and use this website, you are agreeing to
            comply with and be bound by the following terms and conditions of
            use, which together with our privacy policy govern Motive
            Biosciences’ relationship with you in relation to this website. If
            you disagree with any part of these terms and conditions, please do
            not use our website.
          </p>
          <p className="typography-p mb-2">
            The term MotiveBio or ‘us’ or ‘we’ refers to the owner of the
            website whose registered office is Motive Biosciences Inc., 445
            Medical Center Blvd., Webster, TX 77598, U.S.A. (hereinafter
            “MotiveBio”). The term ‘you’ refers to the user or viewer of our
            website.
          </p>
        </Grid>
        <Grid item xs={12}>
          <p className="typography-p mb-2">
            The use of this website is subject to the following terms of use:
          </p>
          <ul className="typography-p">
            <li>
              The content of the pages of this website is for your general
              information and use only. It is subject to change without notice.
            </li>
            <li>
              This website’s content is not a substitute for professional
              medical care or diagnosis. The information contained within is
              provided for general information only and is limited to
              information that is made available and should not be considered as
              all-inclusive or unbiased. As such, the material on this website
              should not be relied upon as the sole basis of decision making.
              Any reliance of the material on this site is at your own risk. We
              reserve the right to modify the contents of the site at any time,
              but we have no obligation to update any information on our site.
            </li>
            <li>This website uses cookies to monitor browsing preferences.</li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness or
              suitability of the information and materials found or offered on
              this website for any particular purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors and
              we expressly exclude liability for any such inaccuracies or errors
              to the fullest extent permitted by law.
            </li>
            <li>
              Your use of any information or materials on this website is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services or information available through this website meet your
              specific requirements.
            </li>
            <li>
              This website contains material which is owned by or licensed to
              us. This material includes, but is not limited to, the design,
              layout, look, appearance and graphics. Reproduction is prohibited
              other than in accordance with the copyright notice, which forms
              part of these terms and conditions.
            </li>
            <li>
              Trademarks reproduced in this website, which are not the property
              of, or licensed to the operator, are acknowledged on the website.
            </li>
            <li>
              Unauthorized use of this website may give rise to a claim for
              damages and/or be a criminal offense.
            </li>
            <li>
              From time to time, this website may also include links to other
              websites. These links are provided for your convenience to provide
              further information. They do not signify that we endorse the
              website(s). We have no responsibility for the content of the
              linked website(s).
            </li>
            <li>
              You agree to hold MotiveBio, its owners, agents, employees,
              affiliates, partners, contractors, licensors, suppliers and
              service providers harmless from any claim or demand, including
              attorney fees and costs, made by you or any third-party due to
              your breach of these Terms of Service or the documents
              incorporated by reference, or your violation of any law or the
              rights of a third-party.
            </li>
            <li>
              You are prohibited from using this site or its content: (a) for
              any unlawful act; (b) to solicit others to participate in any
              unlawful acts; to violate any international, federal, state or
              provincial regulations, laws or local ordinances; (d) to infringe
              upon the intellectual property rights of us or others; (e) to
              harass, insult, slander or discriminate based on gender, religion,
              ethnicity, race, age, sexual orientation or disabilities.
            </li>
            <li>
              Your use of this website and any dispute arising out of such use
              of the website is subject to the laws of the State of Texas,
              U.S.A.
            </li>
          </ul>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(styles, { withTheme: true })(withRoot(TosPage));
