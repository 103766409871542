/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = 'We empower women to take control of their fertility health and optimize engagement with their physicians. MotiveBio is dedicated to making fertility health testing convenient and affordable with at-home fertility hormone tests and personalized health reporting.'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || 'MotiveBio - Your personalized fertility health test'}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content:
            title || 'MotiveBio - Your personalized fertility health test',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: `fertility, personalized health, fitness, wellness, reproductive health, reproductive function, ovary function, healthtech, digital health, menstrual period, period, period tracker, menstrual cycle, menstrual cycle length, menstrual health, premenstrual syndrome, PMS, menstrual irregularities, ovulation tracker, fertile window, fertility awareness, calendar method, patient engagement, patient empowerment, research and discovery, polycystic ovary syndrome, PCOS, ovarian reserve, diminished ovarian reserve, DOR, primary ovarian insufficiency, POI, premature ovarian failure, POF, Premature ovarian aging, POA, menopause, vasomotor symptoms, oncofertility, fertility preservation, gonadotoxicity, ovarian age, ovary health, aromatase inhibitor, metabolic disease, hormonal disorders, hormone imbalance, Metabolic Syndrome, MetS, hyperandrogenism, HA, hyperandrogenicity, androgenicity, modified Ferriman-Gallwey, hirsutism, body hair growth, oligomenorrhea, anovulation, obesity, insulin resistance`,
        }
      ]
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
